//font-family
.font-base {
  font-family: $font-family-base !important;
}
.font-headings {
  font-family: $headings-font-family !important;
  font-weight: 700;
}
.font-alternative {
  font-family: $font-family-alternative !important;
}

strong {
  font-weight: 700 !important;
}

//spacing
.spacing-small {
  @extend .py-2;
}

.spacing-medium {
  @extend .py-4;
}

.spacing-large {
  @extend .py-5;
}

//Borders
/* _border-width-customs.scss */
$border-width-custom-1: 1px !default;
$border-width-custom-2: 2px !default;
$border-width-custom-3: 3px !default;
$border-width-custom-4: 4px !default;
$border-width-custom-5: 5px !default;
$border-width-custom-6: 6px !default;
$border-width-custom-7: 7px !default;
$border-width-custom-8: 8px !default;
$border-width-custom-9: 9px !default;
$border-width-custom-10: 10px !default;

$border-width-customs: (
  '1': $border-width-custom-1,
  '2': $border-width-custom-2,
  '3': $border-width-custom-3,
  '4': $border-width-custom-4,
  '5': $border-width-custom-5,
  '6': $border-width-custom-6,
  '7': $border-width-custom-7,
  '8': $border-width-custom-8,
  '9': $border-width-custom-9,
  '10': $border-width-custom-10,
);

@each $name, $size in $border-width-customs {
  @each $var in '', 'top-', 'right-', 'bottom-', 'left-' {
    .border-#{$var}#{$name} {
      border-#{$var}width: $size !important;
      border-#{$var}style: solid;
      border-#{$var}color: $border-color;
    }
  }
}

//fa icons
.fa-file-pdf:before {
  content: '\f1c1';
}

.fa-external-link-alt:before {
  content: '\f08e';
}

// for long links
.dont-break-out {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.hangingindent {
  padding-left: 10px;
  text-indent: -10px;
}

#cookieBar {
  .btn-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
  }
  .close {
    opacity: 1;
    text-shadow: none;
    font-size: $font-size-base;

    @include media-breakpoint-up(md) {
      position: relative;
      top: 0;
      right: -30px;
    }
  }
}

.graph_zoom_component {
  .graph_container {
    position: relative;

    .expand_icon {
      position: absolute;
      bottom: 20px;
      right: 20px;
    }
  }
}

.graphModal {
  .close {
    top: 10px;
    right: 15px;
  }
}

.panzoom-wrapper {
  position: relative;
  padding-top: 5rem;
  padding-bottom: 5rem;

  .imgScale {
    position: absolute;
    bottom: 15px;
    right: 15px;
    background-color: rgba(0, 0, 0, 0.7);
    color: $white;
    font-size: 13px;
    line-height: 1.2;
    box-sizing: border-box;
    padding: 5px 10px;
    //width: 66px;
  }
}

.expand_icon,
.zoomIn,
.zoomOut {
  &:focus {
    outline: none;
  }
}

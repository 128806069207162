body {
  font-variant-numeric: lining-nums;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: inherit;
  font-weight: 700;
}

.h1,
h1 {
  font-size: 2.1875rem;
}

h1 {
  @include media-breakpoint-down(md) {
    font-size: 22px !important;
  }
}

.h2,
h2 {
  font-size: 1.5rem;
}

ol,
ul {
  position: relative;
  padding-left: 20px;
}

ul {
  li {
    &:not(.nav-item) {
      position: relative;

      list-style: none;

      &::before {
        position: absolute;
        content: '\2022'; /* Add content: \2022 is the CSS Code/unicode for a bullet */
        color: $brand-warning; /* Change the color */
        font-weight: bold; /* If you want it to be bold */
        display: inline-block; /* Needed to add space between the bullet and the text */
        width: 1em; /* Also needed for space (tweak if needed) */
        margin-left: -1em; /* Also needed for space (tweak if needed) */
        vertical-align: top;
        font-size: 20px;
        margin-top: -0.2em;
      }
      p {
        color: $text-color;
        display: inline-block;
      }

      li {
        &::before {
          content: '\25CB';
        }
      }
    }
    &.dropdown-item {
      &:before {
        display: none;
        content: '';
      }
    }
  }
}

sup {
  font-size: 65%;
}

.card.rounded {
  border-radius: 10px !important;
}

.hero_wrapper {
  .container {
    @include media-breakpoint-down(sm) {
      background-color: $brand-secondary;
    }
  }
}

#discussion-guide {
  .acadia-form form {
    button.btn.next {
      @include media-breakpoint-only(lg) {
        right: 40px;
      }
    }
  }
}

#sign-up-form {
  .acadia-form form > row > div {
    @extend .offset-lg-0;
    @extend .col-lg-12;
    @extend .offset-xl-2;
    @extend .col-xl-12;
  }
}

body {
  &.page-talking-to-your-healthcare-provider {
    .hero_wrapper {
      .hero_image {
        @include media-breakpoint-only(lg) {
          background-position: -190px center !important;
        }
      }
      > .container > .row {
        @include media-breakpoint-only(md) {
          min-height: 145px !important;
        }
        @include media-breakpoint-only(lg) {
          min-height: 230px !important;
        }
      }
    }
  }

  &.page-about-nuplazid {
    .hero_wrapper {
      .hero_image {
        @include media-breakpoint-only(md) {
          background-position: center bottom;
        }
        @include media-breakpoint-only(lg) {
          background-position: center bottom;
        }
      }
      > .container {
        @include media-breakpoint-only(md) {
          padding-top: 15px !important;
        }

        > .row {
          @include media-breakpoint-only(md) {
            min-height: 140px !important;
          }
          @include media-breakpoint-only(lg) {
            min-height: 150px !important;
          }
        }
      }
    }
  }
}

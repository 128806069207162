.media-carousel-wrapper {
  .media-carousel {
    position: relative;

    .video_play_button {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      transform: translateY(-50%);
      margin: 0 auto;
      max-width: 100px;
      width: 100%; /* IE11 fix - IE11 ignores max-width, if used in conjuntion with margin: 0 auto;*/
      z-index: 10;
      cursor: pointer;
    }

    .video_title {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;

      h3 {
        color: #ffffff;
        padding: 5px 15px;
      }
    }

    .carousel-indicators {
      position: relative;
      top: 0;
      left: 0;
      margin: 0 auto;

      li {
        position: relative;
        background-color: transparent;
        border-radius: 0;
        display: inline-block;
        height: auto;
        margin: 0 !important;
        width: calc(100% / 4);

        img {
          display: block;
        }

        .video_play_button {
          width: 40px;
        }
      }
    }
  }

  .media-carousel-thumbs {
    position: relative;

    .carousel-item {
      .item-thumb {
        position: relative;
        width: 100%;
        cursor: pointer;
        box-sizing: border-box;
        background-color: $grey-light;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        opacity: 0.5;
        transition: opacity 0.4s;

        &:before {
          display: block;
          content: '';
          width: 100%;
          padding-top: 56.25%;
        }

        &.selected {
          opacity: 1;
        }
      }
    }

    .video_play_button {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      transform: translateY(-50%);
      margin: 0 auto;
      max-width: 40px;
      width: 100%; /* IE11 fix - IE11 ignores max-width, if used in conjuntion with margin: 0 auto;*/
      z-index: 10;
      cursor: pointer;
    }

    .video_title {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;

      h4 {
        color: #ffffff;
        padding: 5px 15px;
        font-size: 16px;
      }
    }

    .carousel-control-next,
    .carousel-control-prev {
      width: 5%;
      opacity: 1;
    }
  }
}

@include media-breakpoint-down(sm) {
  .media-carousel-thumbs
    .carousel-inner
    .active.col-6.carousel-item
    + .carousel-item {
    display: block;
  }
  /* show 2 items */
  .media-carousel-thumbs .carousel-item {
    margin-right: 0;
  }

  .media-carousel-thumbs
    .carousel-inner
    .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left),
  .media-carousel-thumbs
    .carousel-inner
    .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left)
    + .carousel-item {
    transition: none;
  }
  .media-carousel-thumbs .carousel-inner .carousel-item-next {
    position: relative;
    transform: translate3d(0, 0, 0);
  }
  /* left or forward direction */
  .media-carousel-thumbs
    .active.carousel-item-left
    + .carousel-item-next.carousel-item-left,
  .media-carousel-thumbs
    .carousel-item-next.carousel-item-left
    + .carousel-item,
  .media-carousel-thumbs
    .carousel-item-next.carousel-item-left
    + .carousel-item
    + .carousel-item {
    position: relative;
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }
  /* farthest right hidden item must be also positioned for animations */
  .media-carousel-thumbs
    .carousel-inner
    .carousel-item-prev.carousel-item-right {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    display: block;
    visibility: visible;
  }
  /* right or prev direction */
  .media-carousel-thumbs
    .active.carousel-item-right
    + .carousel-item-prev.carousel-item-right,
  .media-carousel-thumbs
    .carousel-item-prev.carousel-item-right
    + .carousel-item,
  .media-carousel-thumbs
    .carousel-item-prev.carousel-item-right
    + .carousel-item
    + .carousel-item {
    position: relative;
    transform: translate3d(100%, 0, 0);
    visibility: visible;
    display: block;
    visibility: visible;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* Show 4th slide on md if col-md-4*/
  .media-carousel-thumbs
    .carousel-inner
    .active.col-md-4.carousel-item
    + .carousel-item
    + .carousel-item
    + .carousel-item {
    position: absolute;
    top: 0;
    right: -33.3333%; /*change this with javascript in the future*/
    z-index: -1;
    display: block;
    visibility: visible;
  }
}

@media (min-width: 576px) {
  .media-carousel-thumbs .carousel-item {
    margin-right: 0;
  }
  /* show 2 items */
  .media-carousel-thumbs .carousel-inner .active + .carousel-item {
    display: block;
  }
  .media-carousel-thumbs
    .carousel-inner
    .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left),
  .media-carousel-thumbs
    .carousel-inner
    .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left)
    + .carousel-item {
    transition: none;
  }
  .media-carousel-thumbs .carousel-inner .carousel-item-next {
    position: relative;
    transform: translate3d(0, 0, 0);
  }
  /* left or forward direction */
  .media-carousel-thumbs
    .active.carousel-item-left
    + .carousel-item-next.carousel-item-left,
  .media-carousel-thumbs
    .carousel-item-next.carousel-item-left
    + .carousel-item,
  .media-carousel-thumbs
    .carousel-item-next.carousel-item-left
    + .carousel-item
    + .carousel-item {
    position: relative;
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }
  /* farthest right hidden item must be also positioned for animations */
  .media-carousel-thumbs
    .carousel-inner
    .carousel-item-prev.carousel-item-right {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    display: block;
    visibility: visible;
  }
  /* right or prev direction */
  .media-carousel-thumbs
    .active.carousel-item-right
    + .carousel-item-prev.carousel-item-right,
  .media-carousel-thumbs
    .carousel-item-prev.carousel-item-right
    + .carousel-item,
  .media-carousel-thumbs
    .carousel-item-prev.carousel-item-right
    + .carousel-item
    + .carousel-item {
    position: relative;
    transform: translate3d(100%, 0, 0);
    visibility: visible;
    display: block;
    visibility: visible;
  }
}
/* MD */
@media (min-width: 768px) {
  /* show 3rd of 3 item slide */
  .media-carousel-thumbs
    .carousel-inner
    .active
    + .carousel-item
    + .carousel-item {
    display: block;
  }
  .media-carousel-thumbs
    .carousel-inner
    .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left)
    + .carousel-item
    + .carousel-item {
    transition: none;
  }
  .media-carousel-thumbs .carousel-inner .carousel-item-next {
    position: relative;
    transform: translate3d(0, 0, 0);
  }
  /* left or forward direction */
  .media-carousel-thumbs
    .carousel-item-next.carousel-item-left
    + .carousel-item
    + .carousel-item
    + .carousel-item {
    position: relative;
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }
  /* right or prev direction */
  .media-carousel-thumbs
    .carousel-item-prev.carousel-item-right
    + .carousel-item
    + .carousel-item
    + .carousel-item {
    position: relative;
    transform: translate3d(100%, 0, 0);
    visibility: visible;
    display: block;
    visibility: visible;
  }
}
/* LG */
@media (min-width: 991px) {
  /* show 4th item */
  .media-carousel-thumbs
    .carousel-inner
    .active
    + .carousel-item
    + .carousel-item
    + .carousel-item {
    display: block;
  }
  .media-carousel-thumbs
    .carousel-inner
    .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left)
    + .carousel-item
    + .carousel-item
    + .carousel-item {
    transition: none;
  }
  /* Show 5th slide on lg if col-lg-3 */
  .media-carousel-thumbs
    .carousel-inner
    .active.col-lg-3.carousel-item
    + .carousel-item
    + .carousel-item
    + .carousel-item
    + .carousel-item {
    position: absolute;
    top: 0;
    right: -25%; /*change this with javascript in the future*/
    z-index: -1;
    display: block;
    visibility: visible;
  }
  /* left or forward direction */
  .media-carousel-thumbs
    .carousel-item-next.carousel-item-left
    + .carousel-item
    + .carousel-item
    + .carousel-item
    + .carousel-item {
    position: relative;
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }
  /* right or prev direction //t - previous slide direction last item animation fix */
  .media-carousel-thumbs
    .carousel-item-prev.carousel-item-right
    + .carousel-item
    + .carousel-item
    + .carousel-item
    + .carousel-item {
    position: relative;
    transform: translate3d(100%, 0, 0);
    visibility: visible;
    display: block;
    visibility: visible;
  }
}

.tab_navigation_horizontal {
  .nav-tabs {
    .nav-item {
      .nav-link {
        .pre-header,
        .sub-header {
          display: block;
          width: 100%;
        }
        .pre-header {
          font-size: 52%;
        }
        .sub-header {
          font-size: 75%;
        }
        h4 {
          padding-top: 10px;
        }
      }
    }
  }
  &.accordion {
    .card-header {
      width: auto !important;
      padding-left: 10px;
      button {
        font-size: 22px;
        line-height: 1.2;
        text-decoration: none !important;
      }
      .pre-header,
      .sub-header {
        display: block;
        width: 100%;
      }
      .pre-header {
        font-size: 52%;
      }
      .sub-header {
        font-size: 75%;
      }
    }
  }
  &.tab_v2 {
    .nav-tabs {
      .nav-item {
        width: 50%;
        border: none;
        margin-bottom: 0;
        .nav-link {
          position: relative;
          border: none;
          &:after {
            content: '';
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            margin: 0 auto;
          }
          &.active {
            &:after {
              width: 0;
              height: 0;
              border-left: 14px solid transparent;
              border-right: 14px solid transparent;
              border-top: 10px solid $brand-primary;
            }
          }
        }
      }
    }
  }
}
.carousel-form {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 0;
  padding-left: 0;
  border-radius: 25px !important;
  border: 1px solid #515151;
  box-shadow: 0 3px 5px 0 rgba(81, 81, 81, 0.75);
  text-align: center;
  width: 90%;
  margin-left: 5%;
  background: $white;
  min-height: 415px;
  h4,
  h5,
  p {
    color: #515151;
  }
  .carousel-item {
    padding: 10px 30px;
  }
  img {
    width: 150px;
    display: block;
    margin: 5px auto;
  }
  p {
    margin: 20px auto;
  }
  label,
  input {
    display: block;
    width: 100%;
  }
  input[type='email'] {
    margin-bottom: 30px;
    padding: 10px;
    border: 1px solid #515151;
    border-radius: 3px;
  }
  input[type='checkbox'] {
    // display: inline;
    // position: absolute;
    // top: 5px;
  }
  .fa {
    color: #0091ff;
    font-size: 40pt;
  }
  .carousel-control-prev {
    left: -15%;
  }
  .carousel-control-next {
    right: -15%;
  }
  .btn {
    border: 2px solid #0091ff;
    color: #0091ff;
    margin: 5px 0;
    width: 190px;
  }
  .btn-large {
    background-color: rgba(21, 154, 254, 1) !important;
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    border: 1px solid #159afe;
    color: #fff !important;
  }
  .tos-description {
    text-align: left !important;
    position: relative;
    top: -8px;
    display: block;
  }
  .slide-number {
    display: block;
    margin-bottom: 10px;
  }
}

// Custom checkboxes
.si {
  --color-default: #212529;
  --color-active: #212529;
  --rotate-default: 180deg;
  --rotate-active: 40deg;
  --border-size-checkmark: 2px;
  --border-size-box: 1px;
  --input-size: 20px;
  --guter: 15px;
}

.si,
.si *,
.si *::before,
.si *::after {
  box-sizing: border-box;
}

.si {
  cursor: pointer;
  position: relative;
}

.si > input[type='checkbox'],
.si > input[type='radio'] {
  clip-path: polygon(0 0);
}

.si .si-label {
  display: inline-block;
  padding-left: 50px;
  color: #515151;
  vertical-align: text-top;
}

.si .si-label::before,
.si .si-label::after {
  transition: all 0.2s ease-in-out;
}

.si .si-label::before {
  content: '';
  display: block;
  width: 30px;
  height: 30px;
  border: var(--border-size-box) solid var(--color-default);
  position: absolute;
  top: -3px;
  left: 0;
  transform: rotate(0deg) scale(1);
}

.si .si-label:hover::before {
  border-color: var(--color-active);
}

.si.si-checkbox .si-label::before {
  border-radius: var(--border-size-checkmark);
}

.si.si-checkbox .si-label::after {
  content: '';
  display: block;
  width: 8px;
  height: 18px;
  border-width: 0 var(--border-size-checkmark) var(--border-size-checkmark) 0;
  border-style: solid;
  border-color: transparent var(--color-active) var(--color-active) transparent;
  position: absolute;
  top: -3px;
  left: 0;
  transform: rotate(var(--rotate-default)) scale(0);
}

.si.si-checkbox > input:checked + .si-label::after {
  left: 8px;
  transform: rotate(var(--rotate-active)) scale(1);
}

.si.si-checkbox > input:checked + .si-label::before {
  transform: rotate(var(--rotate-active)) scale(0);
}

.si.si-radio .si-label::before,
.si.si-radio .si-label::after {
  border-radius: 100%;
  top: -2px;
}

.si.si-radio .si-label::after {
  content: '';
  display: block;
  position: absolute;
  width: 12px;
  height: 0;
  top: 100%;
  left: 4px;
}

.si.si-radio > input:checked + .si-label::after {
  background-color: #fff;
  height: 12px;
  top: 2px;
}

.si.si-radio > input:checked + .si-label::before {
  background-color: var(--color-active);
}

.si.si-switcher .si-label {
  padding-left: 45px;
}

.si.si-switcher .si-label::before {
  content: '';
  width: 36px;
  height: 20px;
  border-radius: 20px;
  top: -2px;
}

.si.si-switcher .si-label::after {
  content: '';
  border-radius: 4px;
  width: 30px;
  height: 30px;
  background-color: var(--color-default);
}

.si.si-switcher > input:checked + .si-label::before {
  background-color: var(--color-active);
  border-color: var(--color-active);
}

.si.si-switcher > input:checked + .si-label::after {
  background-color: #fff;
  left: 24px;
}

.si [data-onchecked='show'],
.si > input:checked ~ [data-onchecked='hide'] {
  display: none;
}

.si [data-onchecked='hide'],
.si > input:checked ~ [data-onchecked='show'] {
  display: inline-block;
}

.si > input:checked + .si-label.line-across {
  text-decoration: line-through;
}

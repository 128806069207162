.callout {
  &:not(.callout_v2),
  &:not(.callout_v3) {
    @include media-breakpoint-down(sm) {
      margin-left: -15px;
      margin-right: -15px;
    }
  }

  &:hover {
    .card-img-hover {
      opacity: 1;
      visibility: visible;
    }
  }

  .card-img-top {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $grey-light;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .card-img-hover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    transition: opacity, visibility, 0.3s;
  }

  .card-text {
    font-family: Lora;
  }

  > a {
    &:hover {
      text-decoration: none;
    }
  }

  [data-toggle='modal'] {
    width: 100%;
    height: 100%;

    &:hover {
      cursor: pointer;
    }
  }

  &.callout_v2,
  &.callout_v3 {
    position: relative;

    > .row {
      min-height: 200px;
    }

    .callout_desc {
      color: $text-color;
      text-decoration: none;

      p {
        color: $text-color;
        text-decoration: none;
      }
    }

    .order-0, .order-1 {
      margin-top: 1rem;
    }

    .callout_cta a.btn {
      width: 100%;

      @include media-breakpoint-up(md) {
        width: auto;
      }
    }
  }

  &.callout_v3 {
    min-height: 175px;
  }
}

#sticky_isi,
#isi {
  .isi-wrapper {
    position: relative;

    li {
      p,
      span {
        color: #f0a055;
      }
    }
  }

  img {
    margin-right: 0.5rem;
    width: 18px;
    @include media-breakpoint-down(md) {
      width: 13px;
    }
  }

  .isi_title {
    position: relative;
    > div {
      p {
        margin-bottom: 0;
      }
    }
  }

  // padding for these paragraphs need to be controlled here, not by the Storyblok UI
  #isi_tray_heading .paragraph_component {
    padding: 15px 0px 15px 20px;

    p {
      margin-bottom: 0;
    }
  }
  #isi_tray_bullet_items .paragraph_component {
    padding: 0px 25px 5px 25px;

    li p {
      padding-bottom: 0.5rem;
      margin-bottom: 0;
    }
  }
} // @END #sticky_isi, #isi

#isi {
  .toggle-btn {
    display: none;
  }
} // @END #isi

#sticky_isi {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 184px;
  transition: height 0.4s linear;
  box-shadow: 0px -1px 9px 0px rgba(0, 0, 0, 0.4);
  z-index: 1000;
  overflow: hidden;
  box-sizing: border-box;

  #isi_tray_heading .paragraph_component {
    padding: 6px 0 6px 20px;

    p {
      margin-bottom: 0;
    }
  }

  #isi_tray_bullet_items .paragraph_component {
    line-height: 24px;
    padding: 0px 20px 5px 20px;

    li {
      display: inline;

      p {
        @include media-breakpoint-up(sm) {
          display: inline;
        }
      }
    }
  }

  ul {
    padding-left: 0 !important;

    li:not(.nav-item) {
      &:before {
        display: none;
      }
    }
  }

  // `on "desktop" show up to the 2nd bullet point`
  @include media-breakpoint-down(lg) {
    height: 204px;
  }
  @include media-breakpoint-down(md) {
    height: 286px;
  }

  // special styles for sticky ISI on mobile
  // `on mobile show up to the 1st bullet point`
  @include media-breakpoint-down(sm) {
    height: 145px;

    .text-primary {
      font-size: 13px;
    }

    #isi_tray_heading .paragraph_component {
      padding: 8px 8px 6px 8px;
      line-height: 15px;
    }

    #isi_tray_bullet_items .paragraph_component {
      line-height: 15px;
      padding: 0;
      p {
        padding: 0 8px 6px 8px;
      }
    }

    ul {
      li:not(.nav-item) {
        p {
          font-size: 13px;
        }
      }
    }
  }

  .btn.toggle-btn {
    display: inline-block;
    text-decoration: none;
    position: relative;
    padding: 0;
    .isi_icon {
      position: relative;
      width: 32px;
      @include media-breakpoint-down(sm) {
        width: 12px;
      }
      height: 32px;
      &:before,
      &:after {
        position: absolute;
        content: '';
        background-color: $white;
      }
      &:before {
        width: 20px;
        @include media-breakpoint-down(sm) {
          width: 12px;
        }
        height: 2px;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
        margin: 0 auto;
      }
      &:after {
        width: 2px;
        height: 20px;
        @include media-breakpoint-down(sm) {
          height: 12px;
        }
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
        margin: 0 auto;
      }
    }
  }
} // @END #sticky_isi

// for tiny sizes to avoid plus icon being pushed below isi title
@media (max-width: 369px) {
  #sticky_isi {
    height: 175px;
    .isi_title {
      width: 84%;
    }
    .btn.toggle-btn .isi_icon {
      height: 52px;
    }
  }
}

.modal-dialog {
  .close {
    position: absolute;
    top: -2px;
    right: 1px;
    float: none;
    opacity: 1;
    z-index: 999;
    font-size: 0.9rem;

    &:hover {
      opacity: 1;
    }
  }

  .modal-body {
    min-height: 75px;
  }
}

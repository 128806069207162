.hbds-example {
  position: relative;
  padding: 1rem;
  margin: 1rem -15px 0;
  border: solid #f8f9fa;
  border-width: 0.2rem 0 0;

  @include media-breakpoint-up(sm) {
    padding: 1.5rem;
    margin-right: 0;
    margin-left: 0;
    border-width: 0.2rem;
  }

  &.sample {
    .row > .col,
    .row > [class^='col-'] {
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      background-color: rgba(86, 61, 124, 0.15);
      border: 1px solid rgba(86, 61, 124, 0.2);
    }
  }
}

.highlight {
  padding: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  background-color: #f7f7f9;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  @include media-breakpoint-up(sm) {
    padding: 1.5rem;
  }
}

.card {
  &.rounded {
    .card-header {
      border-radius: 10px 10px 0 0;
    }
  }

  .card-img-top {
    img {
      width: 200px;

      @include media-breakpoint-up(md) {
        width: 100%;
      }
    }

  }

} // @END .card

.collapse-options {
  color: #4990E2;
  .divider {
    font-weight: 900;
    font-size: 1.75em;
    position: relative;
    top: 3px;
  }
  .btn {
    padding: 5px;
    color: #4990E2;
  }
  svg {
    font-weight: bolder;
    font-size: 2em;
    color: #4990E2;
    position: relative;
    top: -1px;
  }
}
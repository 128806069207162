.graph_component {
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 15px;

  .panzoom-parent {
    position: relative;
  }

  .swipe_icon {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
    transform: translateY(-50%);
    opacity: 1;
    visibility: visible;
    transition: visibility 0s 1s, opacity 1s linear;
    //z-index: -1;

    &.fadeOut {
      opacity: 0;
      visibility: hidden;
    }
  }
}

.paragraph_component {
  p {
    &:last-of-type {
      // margin-bottom: 0;
    }

    .hanging-asterisk {
      display: inline-block;
      margin-left: 1em;

      &:before {
        position: absolute;
        content: '*';
        display: inline-block;
        width: 1em;
        margin-top: 0.2em;
        margin-left: -0.8em;
        vertical-align: top;
      }
    }
  }
}

[data-component_id='bb18f778-56d2-4ef7-b85b-f9549b59e0b8'] {
  .modal {
    .close {
      top: -20px;
      right: -20px;

      @include media-breakpoint-down(sm) {
        top: -25px;
        right: -7px;
      }
      img {
        max-width: 42px;
      }
    }
  }
}

//Header
// also see _navbar_menu.scss

$mobile-start-bkpt: 'lg';
$desktop-start-bkpt: 'xl';
$mobile-font-start-bkpt: 'md';

header {
  position: relative;
  background-color: $grey-light;

  // TODO: remove mobile styles - not used
  @include media-breakpoint-down($mobile-start-bkpt) {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.5);
    // min-height: 140px;
    height: 145px;
  }

  .logo-brand {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  // TODO: delete style - no .nav-bars
  .nav-bars {
    @include media-breakpoint-up($mobile-start-bkpt) {
      flex-flow: row wrap;
    }
  }

  // TODO: delete style - no .logo-wrapper
  .logo-wrapper {
    position: relative;
    display: inline-block;
    z-index: 1;

    @include media-breakpoint-down($mobile-start-bkpt) {
      margin-left: -15px;
    }
  }

  .navbar-brand {
    position: relative;
    z-index: 1;
  }
  .top_nav,
  .bottom_nav {
    width: 100%;
    background: $grey-light;
  } // @END .top_nav, .bottom_nav

  .top_nav {
    position: relative;
    vertical-align: top;

    // provides top & bottom padding mobile needs
    @include media-breakpoint-down($mobile-start-bkpt) {
      // height: 70px;
      height: 60px;
    }
    .container {
      position: relative;
      height: 100%;
    }
    .navbar {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      @include media-breakpoint-down($mobile-start-bkpt) {
        // TODO: remove padding - doesn't seem necessary or used
        padding-top: 1px;
        padding-bottom: 1px;

        height: 100%;
      }
      .navbar-nav {
        // center items for mobile
        @include media-breakpoint-down($mobile-start-bkpt) {
          margin-left: auto !important;
          margin-right: auto !important;
        }
        > .nav-item {
          // TODO: remove mobile styles - doesn't do anything
          @include media-breakpoint-down($mobile-start-bkpt) {
            flex-grow: 1;
            margin-left: 0 !important;
          }

          a {
            @include media-breakpoint-down($mobile-font-start-bkpt) {
              font-size: 14px !important;
            }
          }

          &:first-child {
            a {
              @include media-breakpoint-down($mobile-font-start-bkpt) {
                padding-left: 4px !important;
                padding-right: 0 !important;
              }
            }
          }
          // for Sign-up button
          &:last-child {
            @include media-breakpoint-down($mobile-start-bkpt) {
              // margin-right: 10px;
              margin-left: 15px !important;
            }
          }
        }
      }
    }
    .custom-border {
      position: absolute;
      top: 100%;
      right: -30px;
      height: 2px;
      width: 100%;
      max-width: 956px;
      background-color: #c4c4c4;
      z-index: 1;

      @include media-breakpoint-down($mobile-start-bkpt) {
        top: 97%;
        // max-width: 95%;
        right: 0;
        left: 0;
        margin: 0 auto;
      }
      @include media-breakpoint-down(xs) {
        max-width: 95%;
      }
      &::before,
      &::after {
        content: '';
        position: absolute;
        width: 60px;
        height: 2px;
        background: linear-gradient(
          90deg,
          #ffffff 50%,
          rgba(255, 255, 255, 0) 106.72%
        );
        @include media-breakpoint-down($mobile-start-bkpt) {
          width: 20px;
        }
      }
      &:before {
        left: 0;
      }
      &:after {
        right: 0;
        transform: rotate(180deg);
      }
    }
  } // @END .top_nav

  .bottom_nav {
    // box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
    @include media-breakpoint-down($mobile-start-bkpt) {
      position: relative;
      background: transparent;
    }
    .navbar {
      @include media-breakpoint-down($mobile-start-bkpt) {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
      }
      .navbar-collapse {
        @include media-breakpoint-down($mobile-start-bkpt) {
          position: absolute;
          top: 0 !important;
          left: 0;
          width: 100%;
          z-index: 1000;

          // height given to .bottom_nav
          margin-top: 85px !important;

          // bootstrap `shadow` utility class setting
          box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
        }
        > ul.navbar-nav {
          > .nav-item {
            @include media-breakpoint-down($mobile-start-bkpt) {
              width: 100%;

              // TODO: remove - not used
              margin-left: 0 !important;
            }
            a.nav-link {
              font-weight: 600;

              &:hover {
                text-decoration: underline;
              }

              @include media-breakpoint-down($mobile-start-bkpt) {
                // overwritten by Storyblok settings
                // padding-right & -left also set in Storyblok
                padding-top: 15px !important;
                padding-bottom: 15px !important;

                background-color: $white !important;
                text-align: left !important;

                // TODO: remove - not useful
                border-bottom: 1px solid #fff !important;
              }

              p {
                @include media-breakpoint-down($mobile-start-bkpt) {
                  display: inline;
                }
              }
            }
            &:last-child {
              a.nav-link {
                // @include media-breakpoint-up(md) {
                //   border-bottom: 0 !important;
                // }
                border-bottom: 0 !important;
              }
            }
          }
        }
      }
    }

    // TODO: remove - not needed
    .navbar-brand {
      @include media-breakpoint-down($mobile-start-bkpt) {
        width: auto;
      }
    }
  } // @END .bottom_nav

  .top-bar-navigation {
    position: relative;
  }

  // TODO: remove - not used
  .flex-even {
    flex: 1;
  }
  .flex-md-even {
    @include media-breakpoint-down($mobile-start-bkpt) {
      flex: 1;
    }
  }

  .navbar-nav li {
    &:hover > ul.dropdown-menu {
      border-radius: 0;
      margin-top: 0;
      border: 0;

      // on desktop ignore bootstrap dropdown .show style
      @include media-breakpoint-up($desktop-start-bkpt) {
        display: block;
      }
    }
    > ul.dropdown-menu {
      min-width: 5rem;

      // on desktop ignore bootstrap dropdown .show style
      @include media-breakpoint-up($desktop-start-bkpt) {
        display: none;
      }
      @include media-breakpoint-down($mobile-start-bkpt) {
        // TODO: remove box-shadow - doesn't do anything
        box-shadow: none !important;

        margin-bottom: 0 !important;
      }
    }
    li.dropdown-item {
      padding-left: 0;
      padding-right: 0;
      &:hover {
        background: transparent;
      }
      a {
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
      }
    }
    &.dropdown {
      .nav-link i.fa {
        @include media-breakpoint-down($mobile-start-bkpt) {
          display: none;
        }
      }
      .dropdown-toggle {
        position: absolute;
        top: 0;
        right: 0;
        height: 52px;
        width: 52px;
        cursor: pointer;

        i.fa {
          font-size: 1.5em;
        }

        &[aria-expanded='true'] i.fa:before {
          content: '\f106'; // up arrow
        }

        &[aria-expanded='true']:hover i.fa:before {
          content: '\f107'; // up arrow
        }

        &[aria-expanded='false'] i.fa:before {
          content: '\f107'; // down arrow
        }

        &[aria-expanded='false']:hover i.fa:before {
          content: '\f106'; // up arrow
        }
      }
      &.show {
        .dropdown-menu {
          li.dropdown-item {
            a.nav-link {
              background: #f3f8fa !important;
              @include media-breakpoint-down($mobile-start-bkpt) {
                padding-top: 15px !important;
                padding-bottom: 15px !important;
                background-color: transparent !important;
                border-bottom: 1px solid #eeeeee !important;
              }
            }
          }
        }
      }
    }
  } // @END .navbar-nav li

  .dropdown-submenu {
    position: relative;
  }
  .dropdown-submenu > .dropdown-menu {
    top: 0;
    /* 10rem is the min-width of dropdown-menu */
    left: -10rem;
    margin-top: -6px;
  }
  /* rotate caret on hover */
  .dropdown-menu > li > a:hover:after {
    text-decoration: underline;
    transform: rotate(-90deg);
  }
}

$mobile-start-bkpt: 'lg';

.navbar {
  ul.navbar-nav {
    li {
      .nav-link {
        line-height: 1.2;
      }

      &.dropdown {
        &.show,
        &:hover {
          .nav-link {
            .fa.fa-angle-down:before {
              content: '\f106';
            }
          }
        }
        .dropdown-toggle {

          &:after {
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
            transform: rotate(135deg);
            margin-left: 0.225em;
            border-style: solid;
            border-width: 0.25em 0.25em 0 0;
            margin-top: -3px;
            content: '';
            display: none;
            height: 0.45em;
            vertical-align: 0.225em;
            width: 0.45em;
            border-top: 0.15em solid;
            border-right: 0.15em solid;
            border-bottom: 0;
            border-left: 0;
          }

          // i.fa {
          //   @include media-breakpoint-down($mobile-start-bkpt) {
          //     float: right;
          //     font-weight: 700;
          //   }
          // }
        }

        .dropdown-menu {
          min-width: 270px;
          padding-top: 0 !important;
          padding-bottom: 0 !important;
          padding-left: 0 !important;
          padding-right: 0 !important;

          @include media-breakpoint-down($mobile-start-bkpt) {
            // TODO: remove min-width - not necessary
            min-width: 100%;
            margin-top: 0;
          }

          .dropdown-item {
            a.nav-link {
              background-image: url('../images/gray_divider.png');
              background-size: contain;
              background-repeat: no-repeat;
              background-position: bottom center;
              padding-top: 20px !important;
              padding-bottom: 20px !important;
              padding-left: 30px !important;
              padding-right: 30px !important;
              font-weight: 400;
            }

            &:last-of-type {
              a {
                background-image: none;
              }
            }

            &:hover {
              a.nav-link {
                font-weight: 600;
              }
            }
          }
        }
      }
    }
  }

  .navbar-toggler {
    outline: 0;
    padding: 0;

    .navbar-toggler-icon {
      background-size: contain;
      background-repeat: no-repeat;
      width: 40px;
      height: 36px;
    }
  }
}

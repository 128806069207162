.btn {
  border-radius: 3rem;
  border-width: 2px;
  padding-left: 3rem;
  padding-right: 3rem;
  &.btn-lg,
  &.btn-sm {
    border-radius: 3rem;
  }

  &.btn-lg {
    font-size: 18px;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
  }

  .btn-text {
    display: inline-block;
    //line-height: 1.2;
  }

  &:focus {
    box-shadow: none;
  }
}
.btn-default, .btn-primary {
  color: $btn-default-color;
  background-color: $btn-default-bg;
  border-color: $btn-default-bg;

  &:hover {
    color: $btn-default-bg;
    background-color: white;
    border-color: $btn-default-bg;
  }
}

.btn-secondary {
  color: $brand-primary;
  background-color: $brand-secondary;
  border-color: $brand-primary;

  &:hover {
    color: $brand-primary;
    background-color: white;
    border-color: $brand-primary;
  }
}

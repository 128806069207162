.video-component {
  .video-js {
    &.vjs-21by9,
    &.vjs-16by9,
    &.vjs-4by3,
    &.vjs-1by1 {
      width: 100%;
      max-width: 100%;
      height: 0;
    }
    &.vjs-21by9 {
      padding-top: 42.857%;
    }

    &.vjs-16by9 {
      padding-top: 56.25%;
    }

    &.vjs-4by3 {
      padding-top: 75%;
    }

    &.vjs-1by1 {
      padding-top: 100%;
    }
  }
}

.video_modal {
  .close {
    position: absolute;
    top: -18px;
    right: -40px;
  }
}

//  Table of Contents:
//
//   1. Global
//   2. Grid breakpoints
//   3. Color settings
//   4. Base Typography
//   5. Buttons

// 1. Global
// ---------
//## Settings for some of the most global styles.

//** Background color for `<body>`.
$body-bg: $global_colors-body_background !default;
//** Global text color on `<body>`.
$text-color: $global_colors-body_text_color !default;

//** Global textual link color.
$link-color: $global_colors-link_color !default;
$link-hover-color: $global_colors-link_color_hover !default;
$link-hover-decoration: underline !default;

.h1,
h2,
h3,
h4,
h5 {
  color: $global_colors-heading_color;
}

sup {
  font-size: 55%;
}

//2. Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$grid-breakpoints: (
  xs: $breakpoints_xsmall,
  sm: $breakpoints_small,
  md: $breakpoints_medium,
  lg: $breakpoints_large,
  xl: $breakpoints_xlarge,
) !default;
//@include _assert-ascending($grid-breakpoints, '$grid-breakpoints');
//@include _assert-starts-at-zero($grid-breakpoints);

//3. Color Settings

/*
 * Primary color settings
 */

$blue-light: #4a8de4;
$blue-dark: #0c202f;
$red: #e80029;
$black: #000;
$white: #fff;
$grey-x-light: #f1f1f1;
$grey-light: #d2d2d2;
$grey-medium: #d8d8d8;
$grey-dark: #5a5a5a;
$grey-form: #c8c8c8;

$brand-primary: $global_colors-primary_color !default;
$brand-secondary: $global_colors-secondary_color !default;
$brand-success: #5cb85c !default;
$brand-info: $global_colors-info_color !default;
$brand-warning: #f0ad4e !default;
$brand-danger: #d9534f !default;
$brand-light: $global_colors-light_color !default;

$theme-colors: (
  'primary': $brand-primary,
  'secondary': $brand-secondary,
  'info': $brand-info,
  'warning': $brand-warning,
  'danger': $brand-danger,
  'light': $brand-light,
);

//
//4. Typography
//

$font-family-sans-serif: 'quire-sans', 'Helvetica Neue', Helvetica, Arial,
  sans-serif !default;
$font-family-serif: Georgia, 'Times New Roman', Times, serif !default;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace: Menlo, Monaco, Consolas, 'Courier New', monospace !default;
$font-family-base: $font-family-sans-serif !default;

$font-family-alternative: 'quire-sans', sans-serif !default;
$font-size-base: rem($typography-font_size_base) !default;
$font-size-large: ceil(($font-size-base * 1.25)) !default; // ~18px
$font-size-small: ceil(($font-size-base * 0.85)) !default; // ~12px

$font-size-h1: $typography-h1_font_size !default; // ~36px
$font-size-h2: $typography-h2_font_size !default; // ~30px
$font-size-h3: $typography-h3_font_size !default; // ~24px
$font-size-h4: $typography-h4_font_size !default; // ~18px
$font-size-h5: $typography-h5_font_size !default;
$font-size-h6: $typography-h6_font_size !default; // ~12px

//** Unit-less `line-height` for use in components like buttons.
$line-height-base: 1.428571429 !default; // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed: floor(
  ($font-size-base * $line-height-base)
) !default; // ~20px

$headings-font-family: 'quire-sans', sans-serif !default;
$headings-line-height: $typography-headings_line_height !default;
$headings-color: $global_colors-heading_color !default;

//== Buttons
//

$btn-font-weight: normal !default;

$btn-default-color: $global_colors-btn_default_color !default;
$btn-default-bg: $brand-primary !default;
$btn-default-border: darken($global_colors-btn_default_border, 5%) !default;

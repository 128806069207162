.card-media-vone {
  .card-img-top {
    position: relative;
    width: 100%;
    cursor: pointer;
    box-sizing: border-box;
    background-color: $grey-light;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    &:before {
      display: block;
      content: '';
      width: 100%;
      padding-top: 56.25%;
    }
  }
  .card_media_play_icon {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    margin: 0 auto;
    max-width: 60px;
    width: 100%;
    /* IE11 fix - IE11 ignores max-width, if used in conjuntion with margin: 0 auto;*/
    z-index: 10;
    cursor: pointer;
  }
}

.cardMediaModal {
  .modal-dialog {
    .close {
      top: 0;
      right: 10px;
    }
  }
}

.rounded-card-icon {
  margin-top: 50px;
  border-radius: 25px!important;
  border: 1px solid #515151;
  -webkit-box-shadow: 0px 3px 5px 0px rgba(81, 81, 81, 0.75);
  -moz-box-shadow: 0px 3px 5px 0px rgba(81, 81, 81, 0.75);
  box-shadow: 0px 3px 5px 0px rgba(81, 81, 81, 0.75);
  .card-img-top {
    margin-top: 0;
    width: 100px!important;
    height: 100px!important;
    position: absolute;
    left: 45%;
    left: calc(50% - 50px);
    top: -40px;
    img {
      width: 100px;
      height: 100px;
      border-radius: 50px;
    }
  }
  .card-body {
    padding-top: 5em;
  }
}
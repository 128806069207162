// TODO: this file should maybe be renamed `hero_main` (the encompassing component) or `hero_wrapper` (the class used)
.hero_wrapper {
  position: relative;
  max-width: 1380px;
  margin: 0 auto;

  @include media-breakpoint-down(sm) {
    //min-height: 730px;
  }

  .hero_image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }

  h1,
  h2 {
    small {
      font-size: 65%;
    }

    sup {
      font-size: 55%;
    }
  }

  h2 {
    @include media-breakpoint-up(sm) {
      //font-size: 1.5rem;
    }
  }

  .hero_cta {
    @include media-breakpoint-up(sm) {
      font-size: 1.2rem;
    }
  }

  .hero_main_cta a {
    width: 100%;
    @include media-breakpoint-up(md) {
      width: auto;
    }
  }

  .btn {
    @include media-breakpoint-down(md) {
      font-size: 17px !important;
    }
  }
}

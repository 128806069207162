.pullout {
  position: fixed;
  top: 50%;
  left: 100%;
  z-index: 1020;
  background: $brand-primary;
  box-sizing: border-box;
  transition: 0.5s all ease-in-out;
  border-radius: 20px;
  color: $white;
  padding: 10px 15px;
  width: 200px;
  transform: translateY(-50%);

  @media (max-width: 767px) {
    top: 61vh !important;
  }

  /* Galaxy S5 */
  @media screen and (device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
    top: 59vh !important;
  }

  /* Galaxy S9 */
  @media only screen and (min-device-width: 360px) and (max-device-width: 740px) and (-webkit-min-device-pixel-ratio: 4) {
    top: 63vh !important;
  }

  .pullout-tab {
    cursor: pointer;
  }

  p {
    margin-bottom: 0;
  }

  &.is-open {
    margin-left: -200px;
  }

  &.on-load {
    transition-delay: 0.8s;
    transition-duration: 1s;
  }
}

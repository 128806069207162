//footer
.footer {
  width: 100%;
  background-color: $grey-light;

  .nav {
    // center .nav-links
    justify-content: center;

    @include media-breakpoint-up(md) {
      justify-content: left;
      float: left;
    }

    .nav-item {
      margin-bottom: 1rem;

      .nav-link {
        //text-decoration: underline;
        padding-left: 0.5rem;
        padding-right: 0.5rem;

        @include media-breakpoint-up(md) {
          padding-left: 1rem;
          padding-right: 1rem;
        }
      }
    }
  }

  .paragraph_component {
    text-align: center;

    @include media-breakpoint-up(md) {
      text-align: left;
    }
  }

  .row {
    .row {
      // col with logo and address
      .col:nth-child(2) {
        a {
          display: block;
        }
        img {
          display: block;
          width: 74%;
          @include media-breakpoint-up(md) {
            width: 100%;
          }
        }
        .paragraph_component {
          @include media-breakpoint-up(md) {
            text-align: right;
          }
        }
      }
    }
  }
} // @END .footer

.carousel {
  .carousel-item {
    .carousel-caption {
      top: 50px;
      left: 75px;
      right: 0;
      bottom: auto;
      max-width: 936px;
      max-height: 550px;
      background: #ffffff;
      color: $text-color;
    }
  }

  .carousel-indicators {
    li {
      width: 15px;
      height: 15px;
      margin-right: 10px;
      margin-left: 10px;
    }
  }
}

.accordion-horizontal {
  .card {
    border-bottom-width: 1px;
  }
  .card-header {
    position: relative;
    padding-left: 0;
    cursor: pointer;

    .icon_expand,
    .icon_collapse {
      position: absolute;
      top: 50%;
      left: 15px;
      right: 0;
      transform: translateY(-50%);
    }

    .icon_expand {
      display: none;
    }

    &.icon-align-right {
      padding-left: 10px;
      .icon_expand,
      .icon_collapse {
        right: 15px;
        left: auto;
      }
    }

    &.collapsed {
      .icon_expand {
        display: block;
      }
      .icon_collapse {
        display: none;
      }
    }
  }
}

.accordion-option {
  .toggle-accordion {
    &:hover {
      text-decoration: none;
    }
    span {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.card {
  position: relative;

  .card-title {
    margin-bottom: 0.75rem;
  }

  .card-img {
    &.img-top {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      visibility: hidden;
    }
  }

  .bkg-top {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    transition: opacity, visibility, 0.3s;
  }

  &:hover {
    .bkg-top,
    .img-top {
      opacity: 1;
      visibility: visible;
    }
  }
}

.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@include media-breakpoint-down(lg) {
  .bd-placeholder-img-lg {
    font-size: calc(1.475rem + 1vw);
  }
}
